<script setup>
import { ref } from "vue"
import { useConfigStore } from "@/store/config"
import { useConfirm } from "primevue/useconfirm"
import { useToast } from "primevue/usetoast"

const configStore = useConfigStore()
const confirm = useConfirm()
const toast = useToast()

const loading = ref(false)

const exchangeRates = ref([])
const modal = ref(false)

const symbol = ref(null)

const created = () => {
  if (! symbol.value) {
    toast.add({severity:'warn', summary: 'Warning', detail: 'Please fill all value.', life: 3000})
    return
  }

  if (exchangeRates.value.find(x => x.symbol == symbol.value)) {
    toast.add({severity:'warn', summary: 'Warning', detail: 'This symbol exists.', life: 3000})
    return
  }

  exchangeRates.value.push({ symbol: symbol.value, rate: null })

  exchangeRates.value.sort((a, b) => a.symbol > b.symbol ? 1 : -1)

  symbol.value = null
  modal.value = false
}

const query = () => {
  loading.value = true

  configStore.show('forex_exchange_rate').then(({ data }) => {
    if (! data.error) {
      exchangeRates.value = data.config.value || []
      loading.value = false
    }
  })
}

const remove = (data) => {
  confirm.require({
    message: 'Do you want to delete this record?',
    header: 'Delete Confirmation',
    icon: 'pi pi-info-circle',
    acceptClass: 'p-button-danger',
    accept: () => {
      toast.add({severity:'info', summary:'Confirmed', detail:'Record deleted', life: 3000})

      const index = exchangeRates.value.findIndex(x => x.symbol === data.symbol)
      exchangeRates.value.splice(index, 1)
    },
    reject: () => {
      // toast.add({severity:'error', summary:'Rejected', detail:'You have rejected', life: 3000})
    }
  })
}

const update = () => {
  loading.value = true

  configStore.update('forex_exchange_rate', { value: exchangeRates.value }).then(({ data }) => {
    if (data.error) {
      toast.add({severity:'warn', summary: 'Warning', detail: data.error.message, life: 3000})
      
      return
    }

    toast.add({severity:'success', summary: 'Success', detail: 'Success (#forex_exchange_rate)', life: 3000})

    query()
  }).catch((e) => {
    toast.add({severity:'error', summary: 'Error (Catch)', detail: e.message, life: 3000})
  })
}

query()

</script>

<template>
  <div class="block-section mb-3">
    <div class="block-header mb-2">
      <span class="block-title">
        <div>Exchange Rate</div>
      </span>
    </div>
    <div class="block-content">
      <div class="surface-ground px-4 py-3 md:px-6 lg:px-8 flex align-items-center justify-content-center">
        <div class="surface-card p-4 border-round w-full lg:w-6">
          <div class="p-fluid">
            <div class="card">
              <Button label="Create" icon="pi pi-plus" class="p-button-sm mb-3" @click="modal = true" />
              <Dialog header="Create Trading" v-model:visible="modal" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :modal="true">
                  <label class="block text-900 font-medium mb-2" for="symbol">Symbol</label>
                  <InputText v-model="symbol" id="symbol" type="text" class="w-full mb-3" />
                  
                  <template #footer>
                    <Button label="Cancel" icon="pi pi-times" @click="modal = false" class="p-button-text"/>
                    <Button label="Submit" icon="pi pi-check" @click="created" autofocus />
                  </template>
              </Dialog>

              <DataTable 
                class="p-datatable-gridlines" 
                dataKey="id" 
                filterDisplay="row"
                :loading="loading" 
                responsiveLayout="scroll"
                :rowHover="true"
                :value="exchangeRates"
              >
                <template #header>
                  <div class="flex justify-content-between flex-column sm:flex-row" />
                </template>
                <template #empty>
                    No records.
                </template>
                <template #loading>
                    Loading. Please wait.
                </template>

                <Column field="symbol" header="Symbols">
                  <template #body="{data}">
                    {{ data.symbol }}
                  </template>
                </Column>

                <Column field="rate" header="Rate">
                  <template #body="{data}">
                    {{ data.rate }}
                  </template>
                </Column>

                <Column field="updated_at" header="Updated At">
                  <template #body="{data}">
                    {{ data.updated_at }}
                  </template>
                </Column>

                <Column header="Options">
                  <template #body="{data}">
                    <Button 
                      icon="pi pi-times" 
                      class="p-button-rounded p-button-danger mr-2 mb-2" 
                      @click="remove(data)" 
                    />
                  </template>
                </Column>

              </DataTable>
              
            </div>
          </div>

          <br/>

          <Button label="Save" class="w-full" :loading="loading" @click="update" />
        </div>
      </div>
    </div>
  </div>
</template>
