<script setup>

import { ref, watch } from "vue"
import { useConfigStore } from "@/store/config"
import { useConfirm } from "primevue/useconfirm"
import { useToast } from "primevue/usetoast"

const configStore = useConfigStore()
const confirm = useConfirm()
const toast = useToast()

const loading = ref(false)
const loading_exchange_rate = ref(false)

const symbols_contract_size = ref([])
const symbol = ref(null)
const contract_size = ref(null)
const category = ref(null)
const type = ref('X:USD')
const x = ref(null)
const x_symbol = ref(null)
const y = ref(1)
const y_symbol = ref('USD')
const currency = ref(null)
const modal = ref(false)
const mode = ref('create')

const exchange_rates = ref([])

const query = () => {
  loading.value = true

  configStore.show('forex_exchange_rate').then(({ data }) => {
    if (! data.error) {
      exchange_rates.value = data.config.value || []
      exchange_rates.value = exchange_rates.value.map(x => ({ ...x, label: `${x.symbol} (${x.rate}$)` }))
      loading_exchange_rate.value = false
    }
  })

  configStore.show('forex_symbols_contract_size').then(({ data }) => {
    if (! data.error) {
      symbols_contract_size.value = data.config.value || []
      loading.value = false
    }
  })
}

const openModal = (_mode = 'create', _data = {}) => {
  category.value = _data.category
  contract_size.value = _data.contract_size
  symbol.value = _data.symbol
  x_symbol.value = _data.x_symbol
  y_symbol.value = _data.y_symbol
  currency.value = _data.currency
  type.value = _data.type

  mode.value = _mode
  modal.value = true
}

const remove = (data) => {
  confirm.require({
    message: 'Do you want to delete this record?',
    header: 'Delete Confirmation',
    icon: 'pi pi-info-circle',
    acceptClass: 'p-button-danger',
    accept: () => {
      toast.add({severity:'info', summary:'Confirmed', detail:'Record deleted', life: 3000})

      const index = symbols_contract_size.value.findIndex(x => x.symbol === data.symbol)
      symbols_contract_size.value.splice(index, 1)
    },
    reject: () => {
      // toast.add({severity:'error', summary:'Rejected', detail:'You have rejected', life: 3000})
    }
  })
}

const setSymbolsContractSize = () => {
  if (! category.value || ! contract_size.value || ! symbol.value) {
    toast.add({severity:'warn', summary: 'Warning', detail: 'Please fill all value.', life: 3000})
    return
  }

  const index = symbols_contract_size.value.findIndex(x => x.symbol === symbol.value)
  if (index > -1) {
    symbols_contract_size.value[index] = { category: category.value, contract_size: contract_size.value, symbol: symbol.value, type: type.value, x: x.value, x_symbol: x_symbol.value, y: y.value, y_symbol: y_symbol.value, currency: currency.value }
  } else {
    symbols_contract_size.value.push({ category: category.value, contract_size: contract_size.value, symbol: symbol.value, type: type.value, x: x.value, x_symbol: x_symbol.value, y: y.value, y_symbol: y_symbol.value, currency: currency.value })
  }

  symbols_contract_size.value.sort((a, b) => a.symbol > b.symbol ? 1 : -1)

  category.value = null
  contract_size.value = null
  symbol.value = null
  type.value = 'X:USD'
  x.value = null
  x_symbol.value = null
  y.value = 1
  y_symbol.value = 'USD'
  currency.value = null
}

const update = () => {
  loading.value = true

  configStore.update('forex_symbols_contract_size', { value: symbols_contract_size.value }).then(({ data }) => {
    if (data.error) {
      toast.add({severity:'warn', summary: 'Warning', detail: data.error.message, life: 3000})
      return
    }

    toast.add({severity:'success', summary: 'Success', detail: 'Success (#forex_symbols_contract_size)', life: 3000})

    loading.value = false
  }).catch((e) => {
    toast.add({severity:'error', summary: 'Error (Catch)', detail: e.message, life: 3000})
  })
}

const dropdownChanged = ({ value }) => {
  if (value == 'X:USD') {
    x.value = null
    x_symbol.value = null
    y.value = 1
    y_symbol.value = 'USD'
  } else if (value == 'USD:Y') {
    x.value = 1
    x_symbol.value = 'USD'
    y.value = null
    y_symbol.value = null
  } else {
    x.value = null
    x_symbol.value = null
    y.value = null
    y_symbol.value = null
  }
}

watch(x_symbol, (newSymbol) => {
  x.value = (exchange_rates.value.find(x => x.symbol == newSymbol) || { rate: 1 }).rate
})

watch(y_symbol, (newSymbol) => {
  y.value = (exchange_rates.value.find(x => x.symbol == newSymbol) || { rate: 1 }).rate
})

query()

</script>

<template>
  <div class="block-section mb-3">
    <div class="block-header mb-2">
      <span class="block-title">
        <div>Trading</div>
      </span>
    </div>
    <div class="block-content">
      <div class="surface-ground px-4 py-3 md:px-6 lg:px-8 flex align-items-center justify-content-center">
        <div class="surface-card p-4 border-round w-full lg:w-6">
          <div class="p-fluid">
            <div class="card">
              <Button label="Create" icon="pi pi-plus" class="p-button-sm mb-3" @click="modal = true; mode = 'create'" />
              <Dialog header="Create Trading" v-model:visible="modal" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :modal="true">
                  <label class="block text-900 font-medium mb-2" for="category">Category</label>
                  <Dropdown v-model="category" :options="['Forex', 'คริปโตฯ', 'หุ้น', 'พลังงาน', 'โลหะ']" placeholder="Select a Category" class="w-full mb-3" />

                  <label class="block text-900 font-medium mb-2" for="symbol">Symbol</label>
                  <InputText v-model="symbol" id="symbol" type="text" class="w-full mb-3" :readonly="mode == 'update'" />
                  
                  <label class="block text-900 font-medium mb-2" for="currency">Currency</label>
                  <InputText v-model="currency" id="currency" type="text" class="w-full mb-3" />
                  
                  <label class="block text-900 font-medium mb-2" for="type">Type</label>
                  <Dropdown v-model="type" :options="['X:USD', 'USD:Y', 'X:Y']" placeholder="Select a Type" class="w-full mb-3" @change="dropdownChanged"/>

                  <div class="grid">
                    <div class="col-6">
                        <label class="block text-900 font-medium mb-2" for="x_symbol">X</label>
                        <InputText v-if="type == 'USD:Y'" class="w-full mb-3" readonly value="USD (1$)" />
                        <Dropdown 
                          v-else
                          v-model="x_symbol"
                          class="w-full mb-3"
                          id="x_symbol"
                          :loading="loading_exchange_rate"
                          :options="exchange_rates"
                          optionLabel="label"
                          optionValue="symbol"
                          placeholder="Select a symbol"
                        />
                    </div>
                    <div class="col-6">
                        <label class="block text-900 font-medium mb-2" for="y_symbol">Y</label>
                        <InputText v-if="type == 'X:USD'" class="w-full mb-3" readonly value="USD (1$)" />
                        <Dropdown 
                          v-else
                          v-model="y_symbol"
                          class="w-full mb-3"
                          id="y_symbol"
                          :loading="loading_exchange_rate"
                          :options="exchange_rates"
                          optionLabel="label"
                          optionValue="symbol"
                          placeholder="Select a symbol"
                        />
                    </div>
                  </div>

                  <label class="block text-900 font-medium mb-2" for="contract_size">Contract Size</label>
                  <InputText v-model="contract_size" id="contract_size" type="text" class="w-full mb-3" />
                  
                  <template #footer>
                    <Button label="Cancel" icon="pi pi-times" @click="modal = false" class="p-button-text"/>
                    <Button label="Submit" icon="pi pi-check" @click="modal = false; setSymbolsContractSize()" autofocus />
                  </template>
              </Dialog>

              <DataTable 
                class="p-datatable-gridlines" 
                dataKey="id" 
                filterDisplay="row"
                :loading="loading" 
                responsiveLayout="scroll"
                :rowHover="true"
                :value="symbols_contract_size"
              >
                <template #header>
                  <div class="flex justify-content-between flex-column sm:flex-row" />
                </template>
                <template #empty>
                    No records.
                </template>
                <template #loading>
                    Loading. Please wait.
                </template>

                <Column field="category" header="Category">
                  <template #body="{data}">
                    {{ data.category }}
                  </template>
                </Column>

                <Column field="symbol" header="Symbols">
                  <template #body="{data}">
                    {{ data.symbol }}
                  </template>
                </Column>

                <Column field="type" header="Type">
                  <template #body="{data}">
                    {{ (
                        exchange_rates.find(x => x.symbol == data.x_symbol) 
                        || 
                        { rate: 1 }
                      ).rate 
                    }}
                    :
                    {{ (
                        exchange_rates.find(x => x.symbol == data.y_symbol) 
                        || 
                        { rate: 1 }
                      ).rate 
                    }}
                    ({{ data.type }})
                  </template>
                </Column>

                <Column field="contract_size" header="Contract Size" style="min-width:12rem">
                  <template #body="{data}">
                    {{ data.contract_size }}
                  </template>
                </Column>

                <Column header="Options">
                  <template #body="{data}">
                    <Button 
                      icon="pi pi-pencil" 
                      class="p-button-rounded p-button-warning mr-2 mb-2" 
                      @click="openModal('update', data)" 
                    />

                    <Button 
                      icon="pi pi-times" 
                      class="p-button-rounded p-button-danger mr-2 mb-2" 
                      @click="remove(data)" 
                    />
                  </template>
                </Column>

              </DataTable>
              
            </div>
          </div>

          <br/>

          <Button label="Save" class="w-full" :loading="loading" @click="update" />
        </div>
      </div>
    </div>
  </div>
</template>
