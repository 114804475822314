<script setup>
import { ref } from "vue"
import { useConfigStore } from "@/store/config"
import { useToast } from "primevue/usetoast"

const configStore = useConfigStore()
const toast = useToast()

const loading = ref(false)

const leverage = ref([])

const query = () => {
  loading.value = true

  configStore.show('forex_leverage').then(({ data }) => {
    if (! data.error) {
      leverage.value = data.config.value || []
      loading.value = false
    }
  })
}

const update = () => {
  loading.value = true

  configStore.update('forex_leverage', { value: leverage.value }).then(({ data }) => {
    if (data.error) {
      toast.add({severity:'warn', summary: 'Warning', detail: data.error.message, life: 3000})
      return
    }

    toast.add({severity:'success', summary: 'Success', detail: 'Success (#forex_leverage)', life: 3000})

    loading.value = false
  }).catch((e) => {
    toast.add({severity:'error', summary: 'Error (Catch)', detail: e.message, life: 3000})
  })
}

query()

</script>

<template>
  <div class="block-section mb-3">
    <div class="block-header mb-2">
      <span class="block-title">
        <div>Leverage</div>
      </span>
    </div>
    <div class="block-content">
      <div class="surface-ground px-4 py-3 md:px-6 lg:px-8 flex align-items-center justify-content-center">
        <div class="surface-card p-4 border-round w-full lg:w-6">
          <div class="p-fluid">
            <div class="card">
              <div>
                <label class="block text-900 font-medium mb-2" for="leverage">Leverage</label>
                <Chips id="leverage" v-model="leverage" />
              </div>
            </div>
          </div>

          <br/>

          <Button label="Save" class="w-full" :loading="loading" @click="update" />
        </div>
      </div>
    </div>
  </div>
</template>
